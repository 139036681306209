<template>
    <div>Esporta Modalità Evento CONI</div>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Impostazioni", route: "/settings" },
             { title: "Modalità Evento CONI", route: "/settings/event-mode/index" },
             { title: "Esporta Modalità Evento CONI" }
         ]);
     }
 };
</script>
